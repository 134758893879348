import * as React from 'react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { PrismicProvider } from '@prismicio/react';
import { repositoryConfigs } from '@prismic/utils/repositoryConfigs';
import linkResolver from '@prismic/utils/linkResolver';
import Link from '@components/Link/Link';
import ExternalLink from '@components/ExternalLink/ExternalLink';
import htmlSerializer from '@prismic/utils/htmlSerializer';

import 'sanitize.css';
import './src/scss/styles.module.scss';
import 'react-image-lightbox/style.css';
import 'keen-slider/keen-slider.min.css';

export const onRouteUpdate = ({ location }) => {
  // Plausible Analytics
  if (process.env.NODE_ENV === `production` && typeof window.plausible !== `undefined`) {
    window.plausible(`pageview`);
  }

  // Special case for pages that want smooth scroll
  if (typeof document === 'object') {
    if (['/academy/marketplace-video-course/', '/features/'].includes(location.pathname)) {
      document.documentElement.style.scrollBehavior = 'smooth';
    } else {
      document.documentElement.style.scrollBehavior = 'auto';
    }
  }

  scrollToAnchor(location, 100);
};

export const onInitialClientRender = () => {
  const urlParams = new URLSearchParams(window.location.search);

  // Check if a rfsn value exists in the URL
  if (urlParams.has('rfsn')) {
    const paramValue = urlParams.get('rfsn');

    // Store the rfsn value in localStorage
    localStorage.setItem('rfsn', paramValue);
  }

  // If a user opens a link in navigation in a new tab, referrer is set as the previous page they were viewing. This avoids storing any
  // www.sharetribe.com pages as referrer
  const isReferrerFromSharetribe = document.referrer.includes('www.sharetribe.com');

  // Store the referrer URL in localStorage if it doesn't already exist
  if (
    !localStorage.getItem('referrer') &&
    document.referrer.length > 0 &&
    !isReferrerFromSharetribe
  ) {
    localStorage.setItem('referrer', document.referrer);
  }

  // Store first page visited
  if (!isReferrerFromSharetribe && !localStorage.getItem('first_page_visited')) {
    localStorage.setItem('first_page_visited', window.location.pathname);
  }

  if (urlParams.has('ps_xid')) {
    // Only proceed if a partner ID doesn't already exist in localStorage
    if (!localStorage.getItem('ps_partner_key')) {
      localStorage.setItem('ps_xid', urlParams.get('ps_xid'));
      localStorage.setItem('ps_partner_key', urlParams.get('ps_partner_key'));
    }
    // -- first touch attribution
    // If the partner ID param and the stored key are the same,
    // this means the customer clicked on a new affiliate link, so overwrite ps_xid with the new value
    if (localStorage.getItem('ps_partner_key') === urlParams.get('ps_partner_key')) {
      localStorage.setItem('ps_xid', urlParams.get('ps_xid'));
    }
  }
};

function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const item = document.querySelector(`[data-id="${location.hash}"]`)?.offsetTop;

    if (item) {
      window.scrollTo({
        top: item - mainNavHeight,
        behavior: 'smooth',
      });
    }
  }

  return true;
}

// We wrap our app with the PrismicPreviewProvider in order to save our data to the global store
export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    internalLinkComponent={Link}
    externalComponent={ExternalLink}
    richTextComponents={htmlSerializer}
  >
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
  </PrismicProvider>
);
